@if (vm)
{
<mat-form-field [hintLabel]="vm.property.hintText || ''" subscriptSizing="dynamic">
   <mat-label>{{vm.property.displayName}}</mat-label>
   <mat-select [formControl]="vm.formControl" [placeholder]="vm.property.sampleData || ''" [required]="vm.property.isRequired">
      @for (selectionItem of vm.property.itemsSource; track $index) {
        <mat-option [value]="selectionItem.id">{{selectionItem.label}}</mat-option>
      }
   </mat-select>
   <mat-error>{{vm.getErrorMessage()}}</mat-error>
</mat-form-field>
}