@if (vm)
{
@if (vm.group.icon || vm.group.title)
{
<div class="asc-form-title">
   @if (vm.group.icon)
   {
   <asc-form-icon [icon]="vm.group.icon" />
   }
   @if (vm.group.title)
   {
   <h3>{{vm.group.title}}</h3>
   }
</div>
}
@if (vm.group.description)
{
<p class="asc-form-description">{{vm.group.description}}</p>
}
<div class="asc-form-properties">
   @for (property of vm.properties; track $index)
   {
   @switch (property.property.type) {
   @case ('Datepicker') {
   <asc-form-field-datepicker [property]="property" />
   }
   @case ('Dropdown') {
   <asc-form-field-dropdown [property]="property" [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @case ('Text') {
   <asc-form-field-text [property]="property" />
   }
   @case ('TextMultiline') {
   <asc-form-field-text-multiline [property]="property" />
   }
   @default {
   <p>{{property.property.type}}</p>
   }
   }
   }
</div>
}